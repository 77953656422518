import styled from "styled-components";
import { Rem, BP } from "../../commons/Theme";

export const StyledBusinessClientReviews = styled.div``;

export const StyledBusinessClientReviewsItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    display: block;
    min-width: ${Rem(50)};
    max-width: ${Rem(50)};
    height: 1px;

    @media (${BP.ipad}) {
      display: none;
    }
  }
`;

export const StyledBusinessClientReviewsItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: ${Rem(30)};
  min-width: ${Rem(300)};

  @media (${BP.ipad}) {
    margin-left: ${Rem(16)};
    width: 100%;
  }
`;

export const StyledBusinessClientReviewsItemHeader = styled.div`
  display: flex;
  padding-bottom: ${Rem(20)};
  align-items: center;
`;

export const StyledBusinessClientReviewsItemImage = styled.img`
  width: ${Rem(60)};
  height: ${Rem(60)};
  object-fit: cover;
  border-radius: 50%;
  align-self: start;
  margin-right: ${Rem(20)};

  @media (${BP.ipad}) {
    width: ${Rem(110)};
    height: ${Rem(110)};
  }
`;

export const StyledBusinessClientReviewsItemName = styled.div`
  font-weight: 700;
  font-size: ${Rem(15)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(3)};
  text-transform: uppercase;

  @media (${BP.ipad}) {
    letter-spacing: ${Rem(5)};
  }
`;

export const StyledBusinessClientReviewsItemRole = styled.div`
  font-weight: 700;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  opacity: 0.4;
`;

export const StyledBusinessClientReviewsItemCorporate = styled.div`
  font-weight: 700;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  text-transform: uppercase;
  opacity: 0.4;
`;

export const StyledBusinessClientReviewsItemDescription = styled.div`
  font-weight: 900;
  font-size: ${Rem(14)};
  line-height: ${Rem(19)};
  padding-right: 0;

  @media (${BP.ipad}) {
    padding-right: ${Rem(40)};
  }
`;
