import styled from "styled-components";
import {
  Rem, Colors, BP, ShadowBlack,
} from "../../commons/Theme";
import { StyledBusinessClientReviewsItem } from "../BusinessClientReviews/style";

export const StyledBusinessContactUs = styled.div`
  margin-top: '${props => props.noShift === true ? Rem(0) : Rem(-150)}';
  @media (${BP.tablet}) {
    margin-top: ${(props) => props.noShift === true ? Rem(0) : Rem(-560)};
  }
`;

export const StyledBusinessContactUsCards = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  @media (${BP.ipad}) {
    height: 1050px;
    flex-direction: row;
    margin: 0px 40px;
  }
`;

export const StyledBusinessContactUsCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: ${Rem(15)};
  ${ShadowBlack};
  box-sizing: border-box;
  border-radius: ${Rem(6)};
  padding: ${(props) => props.noPadding ? Rem(0) : Rem(15)};
  background-color: ${Colors.white};

  flex-basis: 0;
  flex-grow: 1;

  &:first-child {
    margin-bottom: ${Rem(20)};
  }

  @media (${BP.ipad}) {
    padding: ${(props) => props.noPadding ? Rem(0) : Rem(40)};
    gap: ${Rem(40)};
    border: ${Rem(4)} solid ${Colors.yellow};
    
    &:first-child {
      margin-right: ${Rem(20)};
      margin-bottom: ${Rem(0)};
    }
  }

  ${StyledBusinessClientReviewsItem} {
    min-width: 0;
    margin: 0;
  }
`;

export const StyledBusinessContactUsImage = styled.img`
  max-height: ${Rem(120)};
  width: auto;
`;
export const StyledBusinessContactUsTitle = styled.h2`
  font-weight: 900;
  font-size: ${Rem(30)};
  text-align: center;
  color: ${Colors.yellow};
  padding-bottom: ${Rem(30)};

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
  }
`;

export const StyledBusinessContactUsIframe = styled.iframe`
  width: 100%;
  height: 100%;
  min-height: 1050px;
  @media (${BP.desktop}) {
    min-height: 950px;
  }
`;
